globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"yV3mRK1hOHyZE9sS9N8eD"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/browser';
import { init, setUser } from '@sentry/nextjs';
import { v4 as uuidv4 } from 'uuid';

import { cookieConsentEvents } from './utils/scripts/cookieConsent/cookieConsent';

const DEFAULT_SAMPLE_RATE = 0.5;

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const tracesSampleRate =
  parseFloat(process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE) ||
  DEFAULT_SAMPLE_RATE;

const SENTRY_ENVIRONMENT =
  process.env.ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT;

if (process.env.NODE_ENV === 'production') {
  init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    // Adjust this value in production, or use tracesSampler for greater control
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate,
  });
}

const SENTRY_USER_ID = 'sentry_user_id';

/*
 * Sentry cookies fall under the 'performance' category.
 * Setting the user to null anonymizes any subsequent data
 * if a user changes their preferences.
 */
function handleConsent({ categories }) {
  if (categories.includes === 'PERFORMANCE') {
    const localStorageId = localStorage.getItem(SENTRY_USER_ID);
    const id = localStorageId || uuidv4();

    if (!localStorageId) {
      localStorage.setItem(SENTRY_USER_ID, id);
    }

    setUser({ id });
  } else {
    setUser(null);
  }
}

cookieConsentEvents.on('init', handleConsent);
cookieConsentEvents.on('change', handleConsent);
